import { formatCurrency } from '@angular/common';

export class ServiceProgress {
  constructor(
    public name: string,
    public currency: string,
    public totalAmount: number,
    public isCompleted: boolean,
    public startDate?: Date,
    public endDate?: Date,
  ) { }

  public get totalAmountFormatted(): string {
    return formatCurrency(this.totalAmount, 'en-US', this.currency + " ", undefined, '1.2-2');
  }
}
