import { ModeOfTransportType, TransportModeType } from 'src/app/shared/model/transport-mode.const';
import { PrtEvent } from 'src/app/shared/components/events/prt-event.model';
import { FreightType } from 'src/app/shared/model/freight-type.const';
import { ShipmentLocation } from 'src/app/shared/model/shipment/shipment-details';

export class ShipmentTrail {
  constructor(
    public opId: number,
    public modeOfTransport: ModeOfTransportType | null,
    public transportationMode: TransportModeType | null,
    public freightType: FreightType | string | null,
    public origin: ShipmentLocation = new ShipmentLocation(),
    public destination: ShipmentLocation = new ShipmentLocation(),
    public bl: string,
    public progress?: number | null,
    public events: PrtEvent[] = [],
    public packageIds?: number[],
    public jobNumber?: string,
    public isActive?: boolean

  ) { }
}
