import { MasterData } from 'src/app/shared/model/master-data/master-data.model';

export class VesselCompare {
  constructor(
    public code: string = '',
    public name: string = '',
    public vesselType: MasterData = new MasterData(),
    public imoNumber?: string,
    public beamExtreme?: number,
    public maxDraft?: number,
    public dwt?: number,
    public grossTonnage?: number,
    public loa?: number,
    public IHSVesselType?: any,
    public imageUrl?: any
  ) { }
}