import { ShipmentLocationDto } from './shipment-details.dto';
import { IOperationalProcessPackage, IOperationalProcessContainer } from '../order/job.interface';
import { ShipmentPoDto } from './shipment-po.dto';
import { PrtEvent } from './../../components/events/prt-event.model';
import { ModeOfTransportType, TransportModeType } from '../transport-mode.const';
import { BookingType } from '../booking-type.const';
import { PrtDocument } from '../prt-document.model';
import { FreightType } from '../freight-type.const';
import { ShipmentDetailsLegacyDto } from './shipment-details-legacy.dto';
import { LocationType } from '../location-type.enum';

export class ShipmentDetails {
  constructor(
    public opId: number = 0,
    public jobNumber: string = '',
    public transportationMode: TransportModeType | null = null,
    public modeOfTransport: ModeOfTransportType | null = null,
    public bl: string = '',
    public carrierName: string = '',
    public origin: ShipmentLocation = new ShipmentLocation(),
    public destination: ShipmentLocation = new ShipmentLocation(),
    public progress: number = 0,
    public eta?: Date,
    public etd?: Date,
    public ata?: Date,
    public atd?: Date,
    public vessel?: string,
    public flightNo?: string,
    public bookingType?: BookingType,
    public shipmentType: string = '',
    public freightType: FreightType | string | null = null,
    public modeType: string | null = null,
    public customer: string = '',
    public customerReferenceNo?: string,
    public shipper: string = '',
    public consignee: string = '',
    public originAgentName: string = '',
    public destinationAgentName: string = '',

    public truckNo: string = '',
    public transporterName: string = '',

    public containers: IOperationalProcessContainer[] = [],
    public poList: ShipmentPoDto[] = [],
    public packages: IOperationalProcessPackage[] = [],
    public events: PrtEvent[] = [],
    public documents: PrtDocument[] = [],
    public shipmentDetails: ShipmentDetailsLegacyDto[] | null = null
  ) { }
}

export class ShipmentLocation {
  constructor(
    public locationName: string = '',
    public locationCode: string = '',
    public locationType: string = '',
    public locationDisplayName: string = ''
  ) {
  }

  public static createFromDto(dto: ShipmentLocationDto) {
    return new ShipmentLocation(
      dto?.locationName,
      dto?.locationCode,
      dto?.locationType,
      (dto?.locationType == LocationType.Airport ||
        dto?.locationType == LocationType.Seaport) ? dto?.locationCode : dto?.locationName
    );
  }
}
