import { Observable } from 'rxjs';
import { PrtEventCategory } from './prt-event-category.model';

export class PrtEvent {
  id?: number;
  category?: PrtEventCategory;
  name = '';
  location?: string;
  locationCode?: string;
  date?: Date;
  marker?: boolean;
  loadingLocation$?: Observable<boolean>;
}
