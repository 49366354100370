import { Observable } from 'rxjs';
import { FreightType } from '../freight-type.const';
import { PrtLocation } from '../prt-location.model';
import { ModeOfTransportType, TransportModeType } from '../transport-mode.const';
import { ShipmentLocation } from './shipment-details';

export const ShipmentIndexTokens = {
  split: '{-}'
};

export class ShipmentsIndex {
  constructor(
    public id: string = '',
    public transportationMode: TransportModeType | null = null,
    public modeOfTransport: ModeOfTransportType | null = null,
    public freightType: FreightType | string | null = null,
    public bl: string = '',
    public carrierName: string = '',
    public jobNumber: string = '',
    public customerReferenceNo?: string,
    public origin: ShipmentLocation = new ShipmentLocation(),
    public destination: ShipmentLocation = new ShipmentLocation(),
    public latestEventName?: string,
    public latestEventDate?: Date,
    public shipper?: string,
    public consignee?: string,
    public progress: number | null = null,
    public location?: PrtLocation,
    public shipmentType?: string,
    public jobGroupFk?: number,
    //temp
    public eta?: string,
    public etd?: string,
    public totalVolume?: number,
    public totalWeight?: number,

    //ui only
    public totalVolumeUom$?: Observable<string | undefined>,
    public totalWeightUom$?: Observable<string | undefined>
  ) { }

}

