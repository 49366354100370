import { IMasterData } from 'src/app/shared/model/master-data/master-data.interface';
import { MasterData } from '../../../../shared/model/master-data/master-data.model';

export class Vessel implements IMasterData {
  dwt?: number;
  loa?: number;
  beam?: number;
  grt?: number;
  nrt?: number;
  suezNRT?: number;
  maxDraft?: number;
  iceVesselClass?: IMasterData;
  imageUrl?: string;
  constructor(
    public code: string = '',
    public name: string = '',
    public type?: MasterData | null,
    public imoNumber?: string,
  ) { }
}
