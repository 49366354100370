
export type DAStatus = 'Pending' | 'Approved' | 'Rejected' | 'Cancelled';
export const DAStatusCodeLookup: Record<DAStatus, string> = {
  Pending: '1',
  Approved: '2',
  Rejected: '3',
  Cancelled: '4'
};

export const DAStatusNameLookup: Record<DAStatus, string> = {
  Pending: 'Pending',
  Approved: 'Approved',
  Rejected: 'Rejected',
  Cancelled: 'Cancelled',
};


export const DAStatusNameLookupByCode: Record<string, DAStatus> = {
  '1': 'Pending',
  '2': 'Approved',
  '3': 'Rejected',
  '4': 'Cancelled'
};
